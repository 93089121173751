import { createContext, useState, useEffect } from "react";
import { fetchAppConfig } from "../api/apiHelper";
import { appSettings } from "../config/appSettings";

import { getEventId, getInfo } from "../utils/QueryString";
import { setCssVariables } from "../utils/Tools";
import { isNullOrUndefined } from "../utils/Validator";

const QnAContext = createContext();

export function QnAProvider({ children }) {
  const [vmUser, setVmUser] = useState(null);
  
  const [isConfigLoaded, setIsConfigLoaded] = useState(false);

  const [timeZoneStr, setTimeZoneStr] = useState(appSettings.timeZoneStr);

  const [eventId, setEventId] = useState(null);
  

  const [specificSettings, setSpecificSettings] = useState(
    appSettings.specificConfig
  );
  const [streamConfig, setStreamConfig] = useState(
    appSettings.streamConfig
  );
  const [QnaConfig, setQnaConfig] = useState(appSettings.qnaConfig);
  const [qnaText, setQnaText] = useState(appSettings.qnaText);
  const [text, setText] = useState(appSettings.text);
  const [votingConfig, setVotingConfig] = useState({
    votingText: appSettings.votingText,
    votingConfig: appSettings.votingConfig,
  });
  const [evaluationConfig, setEvaluationConfig] = useState(
    appSettings.evaluationConfig
  );

  const [qnaForceLoginMode, setQnaForceLoginMode] = useState({
    title: "Login",
    content: "login?",
    button1: "No",
    button2: "Login",
  });

  const [anonymLoginMode, setAnonymLoginMode] = useState({
    title: "Login",
    content: "Would you like to login or stay in anonym?",
    button1: "Anonym",
    button2: "Login",
  });

  const [expiredMode, setExpiredMode] = useState(appSettings.expiredModeConfig);

  const [loginSettings, setLoginSettings] = useState(
    appSettings.vmxLoginConfig
  );

  const [roomsConfig, setRoomsConfig] = useState({
    api_has_streaming: null,
    api_is_shown_app: 1,
  });


  const mergeSettings = (pData) => {
    const value = JSON.parse(pData[0]?.translationkey?.translations[0].value);
    setLoginSettings({ ...loginSettings, ...value.loginSettings });
    setQnaConfig({ ...QnaConfig, ...value.qnaModule });
    setQnaText({ ...qnaText, ...value.qnaText });
    setVotingConfig({ ...votingConfig, ...value.votingModule });
    setStreamConfig({...streamConfig, ...value.streamConfig});
    setQnaForceLoginMode({
      ...qnaForceLoginMode,
      ...value.qnaforceLoginModeText,
    });
    setAnonymLoginMode({
      ...anonymLoginMode,
      ...value.anonymLoginModeText,
    });
    setExpiredMode({ ...expiredMode, ...value.expiredModeText });
    // setSpecificSettings(value.appSpecificSettings);
    setSpecificSettings({
      ...specificSettings,
      ...value.appSpecificSettings,
    });
    setEvaluationConfig({
      ...evaluationConfig,
      ...value.evaluationModule,
    });

    setText({
      ...text,
      ...value.text,
    });

    setTimeZoneStr(value.timeZoneStr ? value.timeZoneStr : timeZoneStr);
    if (value?.roomsConfig)
      setRoomsConfig({
        ...roomsConfig,
        ...value.roomsConfig,
      });

    if(value?.colors){
      setCssVariables(value.colors)
    }

    setIsConfigLoaded(true);
  };


  useEffect(() => {
    (async () => {
      console.log("Fetch the event id for context");
      let testeventId = getEventId();
      console.log(testeventId);

      if (isNullOrUndefined(testeventId)) {
        const hostName = window.location.hostname;
        const pathName = window.location.pathname;

        const translatableID =
          hostName === "localhost" || hostName === "staging.qna.at"
            ? `www.qna.at${pathName}` /*"www.qna.at/demo/" */
            : `${hostName + pathName}`;

        console.log(translatableID);

        const result = await fetchAppConfig({
          translatable_id: translatableID,
          translatable_type: "Url",
          use_cache:0
        });
        if (result?.ok) {
          testeventId = parseInt(result?.data[0].event_id);
          mergeSettings(result?.data);
        }
      }
      else{
        const result = await fetchAppConfig({ translatable_id: testeventId, use_cache:0 });
        if (result?.ok) {
          mergeSettings(result?.data);
        }
        console.log(result);
      }
      setEventId(parseInt(testeventId));

    })(); //IIFE
  }, []);

  

  return !isConfigLoaded || !eventId ? (
    <QnAContext.Provider value="Loading123">
      <div>Loading...</div>
    </QnAContext.Provider>
  ) : (
    <QnAContext.Provider
      value={{
        //isSettingConfig,
        QnaConfig,
        qnaText,
        votingConfig,
        setQnaConfig,
        text,
        qnaForceLoginMode,
        anonymLoginMode,
        expiredMode,
        specificSettings,
        streamConfig,
        evaluationConfig,
        timeZoneStr,
        loginSettings,
        eventId,
        roomsConfig,
        vmUser,
        setVmUser,
      }}
    >
      {children}
    </QnAContext.Provider>
  );
}

export default QnAContext;
