/* eslint-disable no-throw-literal */
/* eslint-disable no-useless-escape */

import { isNullOrWhitespace } from "./Validator";

export const getURLParamsObj = function () {
  var params = {};
  var currentURL = window.location.href,
      hash = window.location.hash;
  if(hash.length > 0)
    currentURL = currentURL.replace(hash, "");
  var paramArray = currentURL.split("?");
  if (paramArray.length === 2) {
    var tmpArray = paramArray[1].split("&");
    for (var i = 0; i < tmpArray.length; i++) {
      var param = tmpArray[i];
      var array = param.split("=");
      if (array[1] !== "*") {
        params[array[0]] = array[1];
      }
    }
  }
  return params;
};

/**
 * returns string where QS var updates with the new value
 * @param {QsValue to update} pName 
 * @param {new value} pValue 
 * @returns string
 */
export const updateQsVariableInUrl = (pName="sid", pValue) => {
    //USAGE: location.href = updateQsVariableInUrl("Sid",44444)
    const {location} = window;
    let qsNewParams = "";
    try{
        const qsOriginParams = location.search.replaceAll('?','').split("&");
        const sidIndex = qsOriginParams.findIndex(c=>c.toLowerCase().includes(`${pName.toLowerCase()}=`));
        if(sidIndex > - 1){
            qsOriginParams[sidIndex] = `${pName}=${pValue}`;
        }
        qsNewParams = qsOriginParams.join('&');
    }catch(err){console.log(err);}
    let finalUrl = `${location.origin}${location.pathname}?${qsNewParams}`;
    if(!isNullOrWhitespace(location.hash))
      finalUrl +=`${location.hash}`;
    return finalUrl;
};
window.updateQsVariableInUrl=updateQsVariableInUrl;

export const getRandomNumber = () => {
  const min = 1;
  const max = 1000000000;
  const rand = min + Math.random() * (max - min);
  return Math.floor(rand);
};

export const isHttp =()=>{
    try{
        const {location} = window;
        if(location.hostname === "127.0.0.1" || location.hostname === "localhost")
            return false;
        if (location.protocol !== 'https:')
            return true;
        

    }catch(err){console.log(err);}
    return false;
}

//await delay(5000);
export const delay = ms => new Promise(res => setTimeout(res, ms));

export const loadCss = (path) => {
    return new Promise((resolve, reject) => {
      const gCssDomId = "faculty-dynamic-css";
      if (document.getElementById(gCssDomId)) {
        resolve("fake");
        return; //already loaded
      }
  
      try {
        let head = document.getElementsByTagName("head")[0],
          link = document.createElement("link");
  
        link.id = gCssDomId;
        link.type = "text/css";
        link.rel = "stylesheet";
        link.href = path;
  
        
        link.onload = (pData) => {
          console.log("dynamic css loaded");
          setTimeout(() => resolve("success"), 200);
        };
  
        head.appendChild(link);
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
};

export const setCssVariable=(variableName, variableValue)=> {
    try{
      var r = document.querySelector(':root');
      r.style.setProperty(variableName, variableValue);
      //.setProperty('--my-variable-name', 'pink');
    }catch(err){console.log(err);}
}

/**
 * object in a form {cssVar1Name:cssVar1Value}
 * @param {*} cssVariables 
 */
export const setCssVariables =(cssVariables)=>{
    try {
      var r = document.querySelector(":root");
      let keys = Object.keys(cssVariables);
      keys.map((c) => {
        r.style.setProperty(c, cssVariables[c]);
        return true;
      });
    } catch (err) {
      console.log(err);
    }
}
  

export const Base64 = {

  // private property
  _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
  _replaceEncodeChars: { "+": ".", "/": "_", "=": "-" },
  _replaceDecodeChars: { ".": "+", "_": "/", "-": "=" },

  // public method for encoding
  encode: function (input) {
      var output = "";
      var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
      var i = 0;

      input = Base64._utf8_encode(input);

      while (i < input.length) {

          chr1 = input.charCodeAt(i++);
          chr2 = input.charCodeAt(i++);
          chr3 = input.charCodeAt(i++);

          enc1 = chr1 >> 2;
          enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
          enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
          enc4 = chr3 & 63;

          if (isNaN(chr2)) {
              enc3 = enc4 = 64;
          } else if (isNaN(chr3)) {
              enc4 = 64;
          }

          output = output +
          this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) +
          this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4);

      }

      return output;
  },

  // public method for decoding
  decode: function (input) {
      var output = "";
      var chr1, chr2, chr3;
      var enc1, enc2, enc3, enc4;
      var i = 0;

      input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

      while (i < input.length) {

          enc1 = this._keyStr.indexOf(input.charAt(i++));
          enc2 = this._keyStr.indexOf(input.charAt(i++));
          enc3 = this._keyStr.indexOf(input.charAt(i++));
          enc4 = this._keyStr.indexOf(input.charAt(i++));

          chr1 = (enc1 << 2) | (enc2 >> 4);
          chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
          chr3 = ((enc3 & 3) << 6) | enc4;

          output = output + String.fromCharCode(chr1);

          if (enc3 !== 64) {
              output = output + String.fromCharCode(chr2);
          }
          if (enc4 !== 64) {
              output = output + String.fromCharCode(chr3);
          }

      }

      output = Base64._utf8_decode(output);

      return output;

  },

  // private method for UTF-8 encoding
  _utf8_encode: function (string) {
      string = string.replace(/\r\n/g, "\n");
      var utftext = "";

      for (var n = 0; n < string.length; n++) {

          var c = string.charCodeAt(n);

          if (c < 128) {
              utftext += String.fromCharCode(c);
          }
          else if ((c > 127) && (c < 2048)) {
              utftext += String.fromCharCode((c >> 6) | 192);
              utftext += String.fromCharCode((c & 63) | 128);
          }
          else {
              utftext += String.fromCharCode((c >> 12) | 224);
              utftext += String.fromCharCode(((c >> 6) & 63) | 128);
              utftext += String.fromCharCode((c & 63) | 128);
          }

      }

      return utftext;
  },

  // private method for UTF-8 decoding
  _utf8_decode: function (utftext) {
      var string = "";
      var i = 0;
      var c = 0;
      var c2 = 0;
      var c3 = 0;

      while (i < utftext.length) {

          c = utftext.charCodeAt(i);

          if (c < 128) {
              string += String.fromCharCode(c);
              i++;
          }
          else if ((c > 191) && (c < 224)) {
              c2 = utftext.charCodeAt(i + 1);
              string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
              i += 2;
          }
          else {
              c2 = utftext.charCodeAt(i + 1);
              c3 = utftext.charCodeAt(i + 2);
              string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
              i += 3;
          }

      }

      return string;
  },

  //https://stackoverflow.com/a/5835352/2161784
  url_encode: function (input) {
      if (typeof input == "number") input += "";
      var encodedStr = this.encode(input), self = this;
      return encodedStr.replace(/\+|\/|=/g, function (match) {
          return self._replaceEncodeChars[match];
  });
      //strtr(base64_encode($input), '+/=', '._-')
  },

  url_decode: function (input) {
      if (typeof input == "number") input += "";
      var self = this;
      var decodedStr = input.replace(/\.|\_|\-/g, function (match) {
          return self._replaceDecodeChars[match];
      });
      return this.decode(decodedStr);
  },

}

window.Base64 = Base64;