import React, { useState, useEffect, useContext } from "react";
import moment from "moment-timezone";
import _ from "lodash";
import "./Tabs.scss";
import {
  Countdown,
  Evaluation,
  QnaContainer,
  VotingContainer,
} from "react-vm-component-library";
import { useNavigate, useParams } from "react-router-dom";

import { Modal } from "../modal/Modal";
import QnAContext from "../../context/QnAContext";
import {
  getCurrentQs,
  paramsDecrypt,
  replaceBracketNtoMinute,
} from "../../utils/Helper";
import { useHttp } from "../../hooks/use-https";
import { checkTabs } from "../../utils/Helper";
import routes from "./../../constants/routes.json";
import { isNullOrUndefined } from "../../utils/Validator";
import { getVmBaseUrl } from "../../api/apiHelper";
import StreamContent from "../streamContent/StreamContent";
import { getAccessToken, getEventId,getIsApp, getRoomId, getSessionId, getToken, getUserId } from "../../utils/QueryString";
import { deflate64, inflate64 } from "../../utils/PakoHelper";
import TabMsg from './TabMsg';

const Tabs = ({ isLoggedIn, setIsLoggedIn, encryptMode }) => {
  const [logoutRender, setLogoutRender] = useState(false);
  const [activeTab, setActiveTab] = useState("qna");
  const [loginModalOpen, setLoginModalOpen] = useState(true);
  const [expiredModalOpen, setExpiredModalOpen] = useState(false);
  const [session, setSession] = useState(null);
  const [isFetchingTime, setIsFetchingTime] = useState(true);
  const [expired, setExpired] = useState(false);
  const [modalText, setModalText] = useState({});
  const [votingCallback, setVotingCallBack] = useState(false);
  const [qnaNumberCount, setQnaNumberCount] = useState();
  const [timeDiff, setTimeDiff] = useState(null);
  const [loading, setLoading] = useState(true);
  const [timerShowEvaluation, setTimeShowEvaluation] = useState(false);

  const {
    QnaConfig,
    qnaText,
    votingConfig,
    setQnaConfig,
    specificSettings,
    streamConfig,
    qnaForceLoginMode,
    anonymLoginMode,
    expiredMode,
    evaluationConfig,
    timeZoneStr,
    loginSettings,
    text,
    vmUser,
  } = useContext(QnAContext);

  const [streamSetting, setStreamSetting] = useState(streamConfig);

  const navigate = useNavigate();
  const params = useParams();
  const httpHook = useHttp();
  const sid = paramsDecrypt(params.id, encryptMode);

  const fetchingData = () => {
    const id = sid;
    const url = `https://${getVmBaseUrl()}/v1/contentsessions/${id}.json?include_contents=0&include_resourcegroups=0&include_resourcegroups_body=0&include_contentsessioncomments=0&include_translationkey_mappings=0&include_translationkeys=0&include_translations=0&translationkey_mappings_smart_find=0&include_tags=0&include_time_to_start=0&use_cache=0`;
    httpHook
      .fetchData({ url: url }, (pData) => {
        // const t1 = new Date(pData.starts_at_local)
        //  const start = new Date(pData.starts_at_local);
        //  const t1 = new Date();
        //  const t2 = new Date(pData.ends_at_local);
        //  const isStarted = (t1.getTime() - start.getTime()) / 1000;
        //  const diff = (t2.getTime() - t1.getTime()) / 1000;

        //  if (diff <= 0 || isStarted < 0) {
        //   console.log("timediff does not exist and render")
        //    setTimeDiff(null);
        //  } else {
        //   console.log("time diff exist and rendered")
        //    setTimeDiff(diff);
        //  }
        setSession(pData);
        // if(getRoomId() || getSessionId()){
        //   window._paq.push(['trackEvent', `${getEventId()}`, 'Direct Room View', `${pData?.room?.id}`])
        //      }
        if (pData?.room?.streaming_urls.length > 0) {
          const streamUrlIndex = pData.room.streaming_urls.length > 1 ? 1 : 0;
          setStreamSetting(
            _.merge(streamConfig, {
              playerConfig: {
                streamUrl: pData?.room?.streaming_urls[streamUrlIndex],
              },
            })
          );
        }
      })
      .then(setIsFetchingTime(false));
  };

  const logOut = () => {
    localStorage.removeItem(`${getEventId()}.qna.user`);
    window._paq.push(['trackEvent', `${getEventId()}`, 'User Logout Session', `${sid}`])
    if(getAccessToken() && getUserId()){
      navigate({
        pathname:window.location.pathname,
        search: getToken() ? `token=${deflate64({event_id:getEventId()})}`: `event_id=${getEventId()}`,
      })
    }
    if (specificSettings.qnaForceLogin) {
      setQnaConfig((prevState) => ({
        ...prevState,
        isChairTable: true,
      }));
    }
    setIsLoggedIn(false);
    //  backToRoom();
    setLogoutRender(true);
  };

  useEffect(() => {
    //console.log(specificSettings.availableTabs.indexOf('qna'))
    
   
    if (
      (Number(window.location.pathname.split("/")[2]) ===
        Number(localStorage.getItem("last.sessionId")) ||
        Number(inflate64(window.location.pathname.split("/")[2])) ===
          Number(localStorage.getItem("last.sessionId"))) &&
      isLoggedIn
    ) {
      localStorage.removeItem("last.sessionId");
    }

    const indexOfQna = specificSettings.availableTabs.indexOf("qna");

    if (indexOfQna !== 0) {
      setLoginModalOpen(false);
    }

    if (isFetchingTime) {
      fetchingData();
    }
    // fetchingData();

    setActiveTab(specificSettings.availableTabs[0]);

    if (!specificSettings.qnaForceLogin) {
      setQnaConfig((prevState) => ({
        ...prevState,
        isUserNameWriteable: true,
      }));

      if (specificSettings.showLogin) {
        setQnaConfig((prevState) => ({
          ...prevState,
          isChairTable: false,
        }));
        // setMode("anonymLoginMode");
        setModalText(anonymLoginMode);
      }

      if (!specificSettings.showLogin) {
        setQnaConfig((prevState) => ({
          ...prevState,
          isChairTable: false,
        }));
        // setMode("anonymMode");
        setModalText(null);
      }
    }
    if (specificSettings.qnaForceLogin) {
      if (QnaConfig.userObject.user_id !== null) {
        setQnaConfig((prevState) => ({
          ...prevState,
          isChairTable: false,
        }));
      } else {
        // setMode("qnaForceLoginMode");
        setModalText(qnaForceLoginMode);
        setQnaConfig((prevState) => ({
          ...prevState,
          isChairTable: true,
        }));
      }
    }

    setLoading(false);

    return () => {
      setIsFetchingTime(true);
      setLogoutRender(false);
    };
  }, []);

  useEffect(() => {
    if (!isFetchingTime) {
      //const end = moment.utc(sessionEnd).format();
      // const end = onSiteMoment(sessionEnd, timeZoneStr)
      const end = moment(session?.ends_at_local).tz(timeZoneStr);
      const countDown = setInterval(() => {
        const now = moment(new Date().getTime()).tz(timeZoneStr);

        const timeDiff = end.diff(moment(now), "minutes");
        //  const timeDiff = -1

        console.log(timeDiff, "minutes");

        if (timeDiff <= 0) {
          setExpired(true);
          setExpiredModalOpen(true);
        }
      }, 60000);
      return () => {
        clearInterval(countDown);
      };
    }

    return () => clearInterval();
  }, [isFetchingTime, session, timeZoneStr]);

  const toEvaluation = (link) => {
    window.open(link, "_blank");
  };

  const backToRoom = () => {
    if (specificSettings.showRoomOverview) {
      navigate({
        pathname: routes.HOME,
        search: getCurrentQs(),
      });
    } else {
      navigate({
        pathname: routes.SESSIONEXPIRED,
        search: getCurrentQs(),
      });
    }

    // window.opener = null;
    // window.open("", "_self");
    // window.close();
  };

  const LogIn = () => {
    // safeSessionId({ currentSessionId, eventId });
    window._paq.push(['trackEvent', `${getEventId()}`, 'User Login Session', `${sid}`])
    if (specificSettings.defaultLoginEntrance === "eanCongressLogin") {
      window.location.replace(
        // `http://staging.ean.virtual-meeting.net/#qna/contentsessions/${sid}`
        "https://ean-id.m-anage.com/Account/LoginSalesforce?context=VM&shortname=ean2023&language=en-GB&context=VM"
      );
    } else {
      navigate({
        pathname: "/auth/login",
        search: getCurrentQs(),
      });
    }
  };

  const renderTabs = () => {
    return (
      <ul className="Tabs__nav">
        <li
          className={activeTab === "qna" ? "Tabs__nav--active" : ""}
          onClick={() => {
            !isLoggedIn && setLoginModalOpen(!loginModalOpen);
            setActiveTab("qna");
          }}
          style={
            checkTabs("qna", specificSettings.availableTabs)
              ? null
              : { display: "none" }
          }
        >
          {specificSettings.qnaTabName}
          {!isNullOrUndefined(qnaNumberCount) && (
            <div className="jme-badge">
              {qnaNumberCount >= 100 ? "99+" : qnaNumberCount}
            </div>
          )}
        </li>
        <li
          className={activeTab === "polling" ? "Tabs__nav--active" : ""}
          onClick={() => {
            setActiveTab("polling");
            setVotingCallBack(false);
          }}
          style={
            checkTabs("polling", specificSettings.availableTabs)
              ? null
              : { display: "none" }
          }
          //fas fa-exclamation
        >
          {specificSettings.pollingTabName}
          {votingCallback && <i className="fas fa-poll"></i>}
        </li>
        <li
          className={`${activeTab === "evaluation" ? "Tabs__nav--active" : ""}`}
          onClick={() => setActiveTab("evaluation")}
          style={
            checkTabs("evaluation", specificSettings.availableTabs)
              ? null
              : { display: "none" }
          }
        >
          {specificSettings.evaluationTabName}
        </li>
      </ul>
    );
  };

  const checkShowBeforeLogin = (tabName) => {
    const indexOfTabName = specificSettings?.showAfterLogin.indexOf(tabName);
    if(indexOfTabName !== -1 ) return true;

    return false;
  }

  useEffect(() => {
    if (session?.show_evaluation_before_ends_at_seconds) {
      const end = moment(session?.ends_at_local).tz(timeZoneStr);
      // const end = moment(new Date("5/29/2023 13:26")).tz(timeZoneStr)
      const interval = setInterval(() => {
        const now = moment(new Date().getTime()).tz(timeZoneStr);

        const timeDiff = end.diff(moment(now), "seconds");
        console.log("setting interval not yet", timeDiff);
        if (timeDiff <= session.show_evaluation_before_ends_at_seconds) {
          console.log("setting interval showing", timeDiff);
          setTimeShowEvaluation(true);
          clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [session]);

  // Mon May 29 2023 12:16:50 GMT+0200

  const renderEvaluationContent = () => {
    let currContent = !evaluationLink ? (
      <>
        {session?.evaluation_type === "component" ? (
          <>
            {session?.show_evaluation_before_ends_at_seconds ? (
              <>
              
                {timerShowEvaluation ? (
                  <Evaluation
                    contentsessionId={session.id}
                    eventId={session.event.id}
                    isAnonymous={!checkShowBeforeLogin("evaluation")}
                    useTranslationConfig={
                      evaluationConfig?.useTranslationConfig
                    }
                    userId={QnaConfig.userObject.user_id}
                    access_token={QnaConfig.userObject.access_token}
                    onSubmitShowModal={false}
                  />
                ) : (
                  <TabMsg>
                    {replaceBracketNtoMinute(
                      evaluationConfig?.evaluationBeforeTimerText,
                      session.show_evaluation_before_ends_at_seconds / 60
                    )}
                  </TabMsg>
                )}
              </>
            ) : (
              <Evaluation
                contentsessionId={session.id}
                eventId={session.event.id}
                isAnonymous={!checkShowBeforeLogin("evaluation")}
                useTranslationConfig={evaluationConfig?.useTranslationConfig}
                userId={QnaConfig.userObject.user_id}
                access_token={QnaConfig.userObject.access_token}
                onSubmitShowModal={false}
              />
            )}
          </>
        ) : (
          <TabMsg>{evaluationConfig.noValueText}</TabMsg>
        )}
      </>
    ) : (
      <button
        className="btn Tabs__outlet__evaluation__button"
        onClick={() => toEvaluation(evaluationLink)}
      >
        {evaluationConfig.buttonText}
      </button>
    );
    if (!session?.evaluation_allowed)
      currContent = (
        <TabMsg>
          {evaluationConfig?.noEvaluationForSession}
        </TabMsg>
      );
    return (
      <div
        className="Tabs__outlet__evaluation"
        style={activeTab === "evaluation" ? {} : { display: "none" }}
      >
        {currContent}
      </div>
    );
  };

  if (isFetchingTime && loading) {
    return <div>Loading...</div>;
  }

  const isRoomInStreamSettings =
    streamSetting?.active &&
    streamSetting?.roomsIds.includes(session?.room?.id);

  const isStreamVisible =
    isRoomInStreamSettings &&
    streamSetting?.type === "stream" &&
    session?.room?.streaming_urls?.length > 0;

  const isAudioOnly =
    isRoomInStreamSettings && streamSetting?.type === "chimeAudioOnly";

  let evaluationLink = session?.special_settings?.vm_talk?.mtv_evaluation_link;
  if (session?.evaluation_link) evaluationLink = session?.evaluation_link;
  if (
    evaluationLink?.includes(evaluationConfig.userIdTmpl) &&
    vmUser?.user?.user_id
  )
    evaluationLink = evaluationLink.replace(
      evaluationConfig.userIdTmpl,
      vmUser?.user?.user_id
    );

  return (
    <div className="Tabs container">
      {specificSettings.qnaTopImageHolder && (
        <div className="Tabs__imgHolder">
          <img src={specificSettings.qnaTopImageHolder} alt="" />
        </div>
      )}

      <div className="back-btn-container">
        {specificSettings.showRoomOverview && (
          <button
            className="btn btn-default back-btn-container__backButton"
            onClick={() => backToRoom()}
          >
            <i className="fas fa-angle-left mr-1"></i>
            <span>{text?.txtRoomsOverview || "Rooms Overview"}</span>
          </button>
        )}

        {specificSettings.showLogin && getIsApp() !== "true" ? (
          isLoggedIn ? (
            <button
              className="btn btn-default back-btn-container__loginOutButton"
              onClick={() => logOut()}
            >
              <i className="fas fa-sign-out-alt mr-1"></i>
              <span>Logout</span>
            </button>
          ) : (
            <button
              className="btn btn-default back-btn-container__loginOutButton"
              onClick={() => LogIn()}
            >
              <i className="fas fa-sign-in-alt mr-1"></i>
              <span>Login</span>
            </button>
          )
        ) : null}
      </div>

      {isAudioOnly && (
        <div className="qna-audio-only-content">
          <a href={streamSetting?.url+session?.id} target="_blank" rel="noreferrer" >
             {streamConfig?.txtOpenStream || "Open audio version"}
            <i class="fas fa-headphones-alt"></i>
          </a>
        </div>
      )}
      {isStreamVisible && <StreamContent {...streamSetting} />}

      {specificSettings.showSessionTitle && (
        <div className="title">
          <span>{session?.title}</span>
        </div>
      )}

      {expired && expiredModalOpen && (
        <Modal
          text={expiredMode}
          setLoginModalOpen={setExpiredModalOpen}
          specificSettings={specificSettings}
          action={backToRoom}
        />
      )}

      {loginModalOpen &&
        !isLoggedIn &&
        !!modalText?.title &&
        !!modalText?.button1 &&
        !!modalText?.button2 && (
          <Modal
            text={modalText}
            setLoginModalOpen={setLoginModalOpen}
            specificSettings={specificSettings}
            action={LogIn}
          />
        )}

      <div
        style={
          (loginModalOpen &&
            !isLoggedIn &&
            !!modalText?.title &&
            !!modalText?.button1 &&
            !!modalText?.button2) ||
          expiredModalOpen
            ? { opacity: "10%" }
            : {}
        }
      >
        {renderTabs()}

        {specificSettings.showDurationProgress && (
          // <DurationProgress durationSec={timeDiff} />
          <Countdown
            ends_at={session?.ends_at_local}
            starts_at={session?.starts_at_local}
            timeZoneStrOnSite={timeZoneStr}
          />
        )}

        <div className="Tabs__outlet">
          {checkShowBeforeLogin("qna") &&
          specificSettings.qnaForceLogin &&
          !isLoggedIn ? (
            <span
              style={{
                display: `${activeTab === "qna" ? "flex" : "none"}`,
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.5rem",
              }}
            >
              {qnaText.showAfterLoginText}
            </span>
          ) : (
            <QnaContainer
              session_id={sid}
              base_api={getVmBaseUrl()}
              show={activeTab === "qna" ? true : false}
              qnaConfig={QnaConfig}
              qnaText={qnaText}
              onCommentsCount={(e) => setQnaNumberCount(e)}
              showSessionTitle={QnaConfig.showSessionTitle}
              showInfoTitle={QnaConfig?.showInfoTitle || false}
            />
          )}
          {/* <QnaContainer
            session_id={sid}
            base_api={getVmBaseUrl()}
            show={activeTab === "qna" ? true : false}
            qnaConfig={QnaConfig}
            qnaText={qnaText}
            onCommentsCount={(e) => setQnaNumberCount(e)}
            showSessionTitle={QnaConfig.showSessionTitle}
          /> */}
          <VotingContainer
            session_id={sid}
            socketBaseUrl={getVmBaseUrl()}
            votingText={{ ...votingConfig.votingText }}
            votingConfig={{ ...votingConfig.votingConfig }}
            show={activeTab === "polling" ? true : false}
            user={
              QnaConfig.userObject.user_id === null
                ? null
                : { user_id: QnaConfig.userObject.user_id }
            }
            onVotingStatusChange={(e) => setVotingCallBack(e)}
            onConnectionReload={() => window.location.reload()}
            onConnectionErrorShowButton={true}
          />
          {checkShowBeforeLogin("evaluation") &&
          specificSettings.qnaForceLogin &&
          !isLoggedIn ? (
            <span
              style={{
                display: `${activeTab === "evaluation" ? "flex" : "none"}`,
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.5rem",
              }}
            >
              {evaluationConfig.showAfterLoginText}
            </span>
          ) : (
            renderEvaluationContent()
          )}
        </div>
      </div>
    </div>
  );
};

export default Tabs;
