import React, { useEffect, useState, useContext } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Alert, RoomsOverview } from "react-vm-component-library";

import "./App.scss";
import {
  getEventId,
  getSessionId,
  getRoomId,
  getIsDemo,
  getUserId,
  getToken,
  getInfo,
  getAccessToken,
  getIsApp,
  getReturnUrl
} from "./utils/QueryString";
import routes from "./constants/routes.json";
import Login from "./Components/login/Login";
import Tabs from "./Components/tabs/Tabs";
import QnAContext from "./context/QnAContext";
import { decodeBase64, getCurrentQs, jmtLoginHelper } from "./utils/Helper";
import { store } from "./utils/LocalStorage";
import { useHttp } from "./hooks/use-https";
import { deflate64, inflate64 } from "./utils/PakoHelper";
import { getUserInfo, getVmBaseUrl } from "./api/apiHelper";
import { isNullOrWhitespace } from "./utils/Validator";

function App() {
  // const [eventId, setEventId] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [encryptMode, setEncryptMode] = useState(false);
  const [loginDataSet, setLoginDataSet] = useState(true);

  const {
    QnaConfig,
    setQnaConfig,
    specificSettings,
    timeZoneStr,
    eventId,
    roomsConfig,
    streamConfig,
    setVmUser,
  } = useContext(QnAContext);

  const navigate = useNavigate();
  const httpHook = useHttp();
  // const { trackPageView, trackEvent } = useMatomo()

  const onRoomHandler = (roomObject) => {
    const sid = roomObject.session_id;
    const deflatedSid = deflate64(String(sid));

    window._paq.push([
      "trackEvent",
      `${getEventId()}`,
      "Room view click",
      `${getEventId()}_${roomObject.room_id}`,
    ]);

    if (encryptMode) {
      navigate(
        {
          pathname: `${routes.SESSIONS}/${deflatedSid}`,
          search: getCurrentQs(),
        }
        //{ replace: true }
      );
    } else {
      navigate(
        {
          pathname: `${routes.SESSIONS}/${sid}`,
          search: getCurrentQs(),
        }
        //{ replace: true }
      );
    }

    store("last.sessionId", roomObject.session_id);
  };

  const roomFetching = (qsRid, qsToken) => {
    const url = `https://${getVmBaseUrl()}/v1/contentsessions.json?room_id=${qsRid}&output=mtv&is_sponsor=-1`;
    httpHook.fetchData({ url: url }, (pData) => {
      // setEventId(pData[0].event_id);
      window._paq.push(['trackEvent', `${getEventId()}`, 'Direct Room View', `${getEventId()}_${qsRid}`])
      store("last.sessionId", pData[0].id);
      
      navigate(
        {
          pathname:
            qsToken === null
              ? `${routes.SESSIONS}/${pData[0].id}`
              : `${routes.SESSIONS}/${deflate64(String(pData[0].id))}`,
          search: getCurrentQs(),
        },
        { replace: true }
      );
    });
  };

  // useEffect(() =>  {
  //   if(getRoomId()){
  //     window._paq.push(['trackEvent', `${getEventId()}`, 'Direct Room View', `${getRoomId()}`])
  //   }
  // })


  useEffect(() => {
    console.log("First Url Render");
    const lEventId = getEventId();
    const qsSid = getSessionId();
    const qsRid = getRoomId();
    const qsToken = getToken();
    const qsUserId = getUserId();
    const hostName = window.location.hostname;
    const pathName = window.location.pathname;
    window._paq.push(["trackEvent", `${lEventId}`]);

    if (qsToken !== null) setEncryptMode(true);

    // if (qsUserId !== null) store("qna.user", JSON.parse(inflate64(qsToken)));

    if (qsSid) {
      store("last.sessionId", Number(qsSid));
      // window._paq.push([
      //   "trackEvent",
      //   `${getEventId()}`,
      //   "Direct Session View",
      //   `${qsSid}`,
      // ]);
      navigate(
        {
          pathname:
            qsToken === null
              ? `${routes.SESSIONS}/${qsSid}`
              : `${routes.SESSIONS}/${deflate64(qsSid)}`,
          search: getCurrentQs(),
        },
        { replace: true }
      );
    } else if (qsRid !== null) {
      roomFetching(qsRid, qsToken);
    } else if (!lEventId) {
      setEncryptMode(true);
      let qsPart = `?token=${deflate64(
        JSON.stringify({ event_id: `${eventId}` })
      )}`;
      if (
        !isNullOrWhitespace(window.location.pathname) &&
        window.location.pathname[window.location.pathname.length - 1] !== "/"
      )
        qsPart = `/?token=${deflate64(
          JSON.stringify({ event_id: `${eventId}` })
        )}`;

      navigate({
        //pathname: `/?event_id=${pData[0].event_id}`,
        //pathname: `${window.location.pathname}${qsPart}`,
        pathname: `${qsPart}`,
      });
    }
  }, []);

  useEffect(() => {
    //Change the qna.user to eventId.qna.user (the reason not to put in last is for jwt login component)
    const tokenObject = localStorage.getItem(`${eventId}.qna.user`);
    const qsAccessToken = getAccessToken();
    const qsUserId = getUserId();
    const qsToken = getToken();

    if (specificSettings.showLogin) {
      if (tokenObject !== null && tokenObject) {
        const data = JSON.parse(tokenObject);
        if (
          data?.validUntil &&
          Number(data?.validUntil) > new Date().valueOf()
        ) {
          setIsLoggedIn(true);
          if (
            specificSettings.defaultLoginEntrance === "eanCongressLogin" ||
            specificSettings.defaultLoginEntrance === "vmxLogin"
          ) {
            if (
              specificSettings.showLogin &&
              !specificSettings.qnaForceLogin &&
              data?.user
            ) {
              setQnaConfig((prevState) => ({
                ...prevState,
                userObject: {
                  ...prevState.userObject,
                  name: data.user.firstname,
                  fullName: data.user.firstname + " " + data.user.surname,
                },
              }));
              setLoginDataSet(false);
            } else if (
              specificSettings.showLogin &&
              specificSettings.qnaForceLogin &&
              !data?.user
            ) {
              jmtLoginHelper({
                eventId: eventId,
                tokenObject: tokenObject,
                setVmUser: setVmUser,
                specificSettings: specificSettings,
                setQnaConfig: setQnaConfig,
                setLoginDataSet: setLoginDataSet,
              });
            } else {
              setQnaConfig((prevState) => ({
                ...prevState,
                userObject: {
                  ...prevState.userObject,
                  name: data.user.firstname,
                  fullName: data.user.firstname + " " + data.user.surname,
                  access_token: data.access_token,
                  user_id: data.user_id,
                },
              }));
              // window._paq.push(['trackEvent', `${getEventId()}`,'User Login Id', `${data.user_id}`])
              if  (localStorage.getItem("last.sessionId"))  {
                navigate({
                  pathname:
                    qsToken === null
                      ? `${routes.SESSIONS}/${localStorage.getItem(
                          "last.sessionId"
                        )}`
                      : `${routes.SESSIONS}/${deflate64(
                          localStorage.getItem("last.sessionId")
                        )}`,
                  search: getCurrentQs(),
                });
              }
              setLoginDataSet(false);
            }
          } else {
            jmtLoginHelper({
              eventId: eventId,
              tokenObject: tokenObject,
              setVmUser: setVmUser,
              specificSettings: specificSettings,
              setQnaConfig: setQnaConfig,
              setLoginDataSet: setLoginDataSet,
            });
          }
        } else {
          setIsLoggedIn(false);
          setLoginDataSet(false);
        }
      }

      if (tokenObject === null && !tokenObject) {
        if (qsAccessToken && qsUserId) {
          let date = new Date();

          let userData = {
            event_id: getEventId(),
            session_id: getSessionId(),
            user_id: getUserId(),
            access_token: getAccessToken(),
            user: { firstname: "", surname: "" },
            validUntil: date.setDate(date.getDate() + 14),
          };

          const getUserInfo = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Basic ${btoa("m-events:kims")}`,
            },
          };

          fetch(
            `https://${getVmBaseUrl()}/v1/users/${getUserId()}.json`,
            getUserInfo
          )
            .then((response) => response.json())
            .then((data) => {
              userData = {
                ...userData,
                user: {
                  ...userData.user,
                  firstname: data.firstname,
                  surname: data.surname,
                },
              };
              localStorage.setItem(
                `${eventId}.qna.user`,
                JSON.stringify(userData)
              );
              // window._paq.push(['trackEvent', `${getEventId()}`, 'User Login Id', `${getUserId()}`])
              setIsLoggedIn(true);
            });
        } else {
          setIsLoggedIn(false);
          setQnaConfig((prevState) => ({
            ...prevState,
            userObject: {
              ...prevState.userObject,
              name: "",
              fullName: "",
              access_token: null,
              user_id: null,
            },
          }));
          setLoginDataSet(false);
        }
      }
    } else {
      setLoginDataSet(false);
      localStorage.removeItem(`${eventId}.qna.user`);
      localStorage.removeItem(`qna.user`);
    }
  }, [isLoggedIn]);

  if (eventId === undefined || eventId === null || loginDataSet) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="spinner-border"></div>
      </div>
    );
  }

  if (isLoggedIn && specificSettings.qnaForceLogin) {
    if (
      QnaConfig.userObject.name === "" ||
      QnaConfig.userObject.access_token === null
    ) {
      return (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="spinner-border"></div>
        </div>
      );
    }
  }
  let viewModeListCustomIcon = null;
  if (streamConfig?.active) {
    viewModeListCustomIcon = {
      fontAwesomeClass: "fas fa-headphones-alt",
      roomIds: streamConfig.roomsIds,
    };
  }
  return (
    <div className="qna-v2-main-container">
      <Routes>
        <Route
          exact
          path={routes.HOME}
          element={
            <div className="container">
              {((specificSettings?.roomsOverviewBackBtn?.active ||
                ( getIsApp() && getIsApp() === "true"))) && (
                <button
                  className="btn btn-default"
                  onClick={() => {
                    if (getReturnUrl())
                      return (window.location.href = getReturnUrl());

                    return (window.location.href =
                      specificSettings?.roomsOverviewBackBtn?.href);
                  }}
                >
                  <i className="fas fa-angle-left mr-1"></i>
                  <span>
                    {specificSettings?.roomsOverviewBackBtn?.text || "Back to"}
                  </span>
                </button>
              )}

              <RoomsOverview
                event_id={eventId}
                base_api={getVmBaseUrl()}
                onRoomClick={onRoomHandler}
                timeZoneStr={timeZoneStr}
                isDemo={getIsDemo() ? true : false}
                // api_starts_at="2023-05-29"
                //api_ends_at="2022-09-31"
                liveIconType="type3"
                viewMode="list"
                viewModeListCustomIcon={viewModeListCustomIcon}
                {...roomsConfig}
                /*api_has_streaming={parseInt(
                  specificSettings.roomOverviewApiStreaming
                )}*/
              />
            </div>
          }
        />
        <Route
          exact
          path={`${routes.SESSIONS}/:id`}
          element={
            <Tabs
              isLoggedIn={isLoggedIn}
              setIsLoggedIn={setIsLoggedIn}
              encryptMode={encryptMode}
            />
          }
        />

        <Route
          path={`${routes.LOGIN}/`}
          element={<Login encryptMode={encryptMode} />}
        />
        <Route
          exact
          path={`${routes.SESSIONEXPIRED}/`}
          element={
            <div
              style={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Alert
                style={{
                  margin: "1rem",
                  padding: "1rem",
                  borderRadius: "5px",
                  display: "flex",
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                    textAlign: "center",
                  }}
                >
                  The session expired. To access the next session please scan
                  the corresponding QR Code.
                </span>
              </Alert>
            </div>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
