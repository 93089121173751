import { getEventId } from "./QueryString";
import { inflate64 } from "../utils/PakoHelper";
import { isNullOrWhitespace } from "./Validator";
import { getVmBaseUrl } from "../api/apiHelper";

const safeSessionId = (props) => {
  const currentStorage = JSON.parse(localStorage.getItem("eventSession"));
  localStorage.setItem(
    "eventSession",
    JSON.stringify({
      ...currentStorage,
      current_session_id: props.sessionId,
      current_event_id: props.eventId,
    })
  );
};

const checkTabs = (name, array) => {
  if (array.indexOf(name) > -1) {
    return true;
  } else {
    return false;
  }
};

const getUserData = async ({
  eventId,
  userToken,
  specificSettings,
  setQnaConfig,
}) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      event_id: eventId,
      jwt_token: userToken,
      include_user: 1,
      include_grants: 0,
    }),
  };

  await fetch(`https://${getVmBaseUrl()}/v0/logins/jwt.json`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      if (specificSettings.showLogin && !specificSettings.qnaForceLogin) {
        setQnaConfig((prevState) => ({
          ...prevState,
          userObject: {
            ...prevState.userObject,
            name: data.user.firstname,
            fullName: data.user.firstname + " " + data.user.surname,
          },
        }));
      } else {
        setQnaConfig((prevState) => ({
          ...prevState,
          userObject: {
            ...prevState.userObject,
            name: data.user.firstname,
            fullName: data.user.firstname + " " + data.user.surname,
            access_token: data.access_token,
            user_id: data.user_id,
          },
        }));
      }
    });
};

const decryptParam = (title, url) => {
  const inflatedResult = inflate64(url);

  if (inflatedResult !== null) {
    const paramObject = JSON.parse(inflatedResult);
    const result = paramObject[title];

    if (result === null || result === undefined) {
      return null;
    } else {
      return result;
    }
  } else {
    return null;
  }
};

const paramsDecrypt = (string, encryptMode) => {
  if (encryptMode) {
    const sid = inflate64(string);
    return parseInt(sid);
  } else {
    return parseInt(string);
  }
};

const getCurrentQs = () => {
  let qs = window.location.search;
  return isNullOrWhitespace(qs) ? null : qs;
};

const replaceBracketNtoMinute = (str, minute) => {
  if (str.includes("{n}")) return str.replace("{n}", minute);

  return str;
};

const decodeBase64 = (encodedString) => {
  return atob(encodedString);
};

const jmtLoginHelper = (props) => {
  const {eventId, tokenObject, setVmUser, specificSettings, setQnaConfig, setLoginDataSet} = props;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      event_id: eventId,
      jwt_token: JSON.parse(tokenObject).accessToken,
      include_user: 1,
      include_grants: 0,
    }),
  };

  fetch(
    `https://${getVmBaseUrl()}/v0/logins/jwt.json`,
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      setVmUser(data); 
      if (specificSettings.showLogin && !specificSettings.qnaForceLogin) {
        setQnaConfig((prevState) => ({
          ...prevState,
          userObject: {
            ...prevState.userObject,
            name: data.user.firstname,
            fullName: data.user.firstname + " " + data.user.surname,
          },
        }));
        setLoginDataSet(false)
      } else {
        setQnaConfig((prevState) => ({
          ...prevState,
          userObject: {
            ...prevState.userObject,
            name: data.user.firstname,
            fullName: data.user.firstname + " " + data.user.surname,
            access_token: data.access_token,
            user_id: data.user_id,
          },
        }));
        // window._paq.push(['trackEvent', `${eventId}`, 'User Login Id', `${data.user_id}`])
        setLoginDataSet(false)
      }
    });
};

export {
  safeSessionId,
  checkTabs,
  getUserData,
  decryptParam,
  getCurrentQs,
  paramsDecrypt,
  replaceBracketNtoMinute,
  decodeBase64,
  jmtLoginHelper
};
