import _ from "lodash";

const AUTH_DATA = { Authorization: `Basic ${btoa("m-events:kims")}` };

export const makeHttpCall = async (requestConfig) => {
  const controller = new AbortController();
  // 5 second timeout:
  const timeoutId = setTimeout(() => controller.abort(), 5000);
  const {
    url,
    method = "GET",
    headers = {},
    bodyJson,
    isResponseText = false,
    isVm = false,
  } = requestConfig;
  const apiRequestConfig = {
    method,
    headers,
    body: bodyJson ? JSON.stringify(bodyJson) : null,
    signal: controller.signal,
  };
  if (isVm)
    apiRequestConfig.headers = _.merge(requestConfig.headers, AUTH_DATA, {
      "Content-Type": "application/json",
    });

  try {
    //console.log(`url=${url}`)
    const response = await fetch(url, apiRequestConfig);
    if (!response.ok) {
      throw new Error("Request failed!");
    }

    const data = isResponseText ? await response.text() : await response.json();

    if (Array.isArray(data)) return { ok: true, data: [...data] };
    return { ok: true, ...data };
  } catch (err) {
    console.log(err);
    return { ok: false, ...err };
  }
};
