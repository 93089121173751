import { makeHttpCall } from "../utils/HttpHelpers";
import { getApiUrl, getEventId, getUserId } from "../utils/QueryString";

export const getVmBaseUrl = () => {
  const qsUrl = getApiUrl();
  if (qsUrl) return qsUrl;
  const currUrl = window.location.href.toLowerCase();
  if(currUrl.includes("qna.at/eadv") || currUrl.includes("eadv.qna.at") ||currUrl.includes("/eadv")|| getEventId() === "12")
    return "eadv-api-lb.virtual-meeting.net";
  if(currUrl.includes("qna.at/easd") || currUrl.includes("easd.qna.at") || getEventId() === "19" )
    return "easd-api-lb.virtual-meeting.net";
    
  return "api-lb.virtual-meeting.net";
};

export const fetchAppConfig = async ({translatable_id, translation_language="onsite.qna.config", translatable_type="Event", use_cache=1}) => {
  const url = `https://${getVmBaseUrl()}/v1/translationkey_mappings.json?include_translations=1&include_translatable=1&include_translationkey=1&translation_language=${translation_language}&translatable_type=${translatable_type}&translatable_id=${translatable_id}&use_cache=${use_cache}`;
  return await makeHttpCall({
    //url : `${url}?${Object.keys(params).map(key=>`${key}=${params[key]}`).join('&')}`,
    url,
    method: "GET",
    isVm: true,
  });
};

export const getUserInfo = async() => {
  const url = `https://${getVmBaseUrl()}/v1/users/${getUserId()}.json`;
  return await makeHttpCall({
    url,
    method:"GET",
    isVm:true,
  })
}
