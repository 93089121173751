export const appSettings = {
  test: "test string",
  timeZoneStr: "Europe/Berlin",
  specificConfig: {
    showLogin: false,
    qnaForceLogin: false,
    showSessionTitle: true,
    showRoomOverview: true,
    showDurationProgress: true,
    qnaTabName: "QnA",
    pollingTabName: "Polling",
    evaluationTabName: "Evaluation",
    availableTabs: ["qna", "polling", "evaluation"],
    qnaTopImageHolder: null,
    roomOverviewApiStreaming: 1,
    defaultLoginEntrance: "",
    showAfterLogin: [],
  },
  text: {
    txtRoomsOverview: "Rooms Overview",
  },
  // Future scope, combine the config into module base
  // "qnaModule":{

  // },
  // "votingModule":{

  // },  
  // "roomsModule":{

  // },

  qnaConfig: {
    userObject: {
      name: null,
      fullName: null,
      user_id: null,
      access_token: null,
    },
    moderatorMode: {
      active: false,
      token: "test",
      hideSubManageBtns: false,
    },
    isChairTable: false,
    poolIntervalSec: 60,
    showCollapsed: false,
    commentsPerPage: 7,
    showPerPageList: false,
    textareaMaxChars: 700,
    commentSourceType: "",
    commentPostIsHidden: false,
    commentLikes: true,
    commentHighlights: false,
    orderBy: "time",
    showFilters: false,
    showBookmarks: false,
    isUserNameWriteable: false,
    showSessionTitle: false,
    showAddresToListInForm: true,
    showAnsweredLabel:false,
    isAddresToListMadatory:false
  },
  qnaText: { showAfterLoginText: "Please login to see the qna, thank you." },

  votingConfig: {
    anonymVote: false,
    showIsCorrectAnswer: false,
    showTotalVotes: false,
    resultsMode: "percentage",
  },
  votingText: {
    btnSubmitVote: "Submit",
    votingCorrect: "Correct Answers",
    votingIncorrect: "Incorrect Answers",
    votingSuccess: "You voted.",
    txtPollingWillStartSoon: "Not started.",
    txtPollingWaitingToStart: "Currently no Polling is active.",
    txtVotingNotAllowedToVote:
      "You are not allowed to participate in voting, due to your account restrictions.",
    txtWaitingResults: "Not started.",
    anonymUserCannotVote: "Anonym users can't vote",
    txtTotalVotes: "Total votes",
    txtVote: "vote",
    txtVotes: "votes",
  },

  evaluationConfig: {
    noValueText: "There is no evaluation available for this session",
    noEvaluationForSession: "There will be no evaluation for this session",
    buttonText: "To Evaluation",
    userIdTmpl: "[user_id]",
    showAfterLoginText: "Please login to continue the evaluation, thank you.",
    useTranslationConfig:false
  },

  vmxLoginConfig: {
    id: "manage-login",
    identityUrl: "https://id.m-anage.com/",
    oauthUrl: "https://m-anage.com/OAuth2/Authorize",
    client_id: "",
    apiUrl: "https://api.m-anage.com",
    instance: "qna",
    language: "en-GB",
    short: "vmx",
    context: "Event",
  },

  streamConfig: {
    active: false,
    type:"chimeAudioOnly",//[chimeAudioOnly, stream]
    roomsIds: [],
    txtOpenStream: "Open stream",
    txtCloseStream: "Close stream",
    url:"https://audio.m-events.com/live/?sid=",
    playerConfig: {
      miniPlayerActive: false,
      streamUrl: null,
      //streamUrl: "https://3d26876b73d7.us-west-2.playback.live-video.net/api/video/v1/us-west-2.913157848533.channel.xJ2tVekwmMGd.m3u8"
    },
  },

  expiredModeConfig: {
    title: "Information",
    content:
      "Session is expired. Would you like to stay in the room or leave ?",
    button1: "Stay",
    button2: "Leave",
  },
};

window.appSettings = appSettings;
