import {inflateRaw, deflateRaw} from "pako";
  //examples
    /*
    inflate64("q1YqMFSyUjI0MlbSUSowAjITi1MSU4qLU4D8jPSMdKCIkamRsSmUUKoFAA==")
    deflate64("{\"p1\":\"123\",\"p2\":\"asdadssd\",\"hghg\":\"252352523525\"}", false)
    deflate64("{\"p1\":\"123\",\"p2\":\"asdadssd\",\"hghg\":\"252352523525\"}")
    deflate64({p1: "123", p2: "asdadssd", hghg: "252352523525"}, false)
*/

export const inflate64 = (pBase64String) => {
    try
    {
        if(!pBase64String)
            return null;
        // Get some base64 encoded binary data from the server. Imagine we got this:		
        //pBase64String="q1YqMFSyUjI0MlbSUSowAjITi1MSU4qLU4D8jPSMdKCIkamRsSmUUKoFAA=="
        // Decode base64 (convert ascii to binary)
        var strData = atob(decodeURIComponent(pBase64String));

        // Convert binary string to character-number array
        var charData = strData.split("").map(function (x) {
            return x.charCodeAt(0);
        });
        

        // Turn number array into byte-array
        var binData = new Uint8Array(charData);
        var lData = inflateRaw(binData);
        // Convert gunzipped byteArray back to utf-8 string:
        var lResult = new TextDecoder("utf-8").decode(lData);
    
        return lResult;
       
        
    }
    catch(err)
    {
        console.log(err);
    }
    return null;
};

export const deflate64 = (pObj, pEncode = true) => {
  try{
    
    if(!pObj)
        return null;
    
    const lBase64String = typeof pObj === "object" ? JSON.stringify(pObj):pObj;
    
    var binaryString = deflateRaw(lBase64String, { to: "string" });
    var lEncodedBase64 = btoa(binaryString);
    //console.log(lEncodedBase64);
    return pEncode ? encodeURIComponent(lEncodedBase64): lEncodedBase64;
  }catch(err){console.log(err);}
  return null;
  
};
window.deflate64 = deflate64
window.inflate64 = inflate64;